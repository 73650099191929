//INFO: 프로젝트 게시판 등록
<script>
import { getLabPjt } from '@/api/platform/community'
import LoadingVue from '@/components/ui/Loading.vue' // 로딩 공통 UI
import { GridComponent, ColumnsDirective, Sort, Toolbar, Search, Page } from '@syncfusion/ej2-vue-grids'
import { searchPjt, getTotalPjtData, searchPaperAll, searchPatentAll } from '@/api/admin/ntis/ntis' // NTIS API
import { insertPjtTable, insertPaperTable, insertPatentTable } from '@/api/admin/ntis/ntisSave'

export default {
  name: 'adminBoardProjectCreate',
  components: {
    LoadingVue,
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective
  },
  data() {
    return {
      isLoading: false,
      labNo: this.labNo,

      // ********** 그리드 세팅 **********
      toolbarOptions: ['Search'], // 툴바에 검색 옵션
      pageSettings: { pageSize: 10 }, // 10개씩 잘라서 페이지네이션

      // ********** 검색 **********
      searchedProjectData: [], // 프로젝트 검색 결과
      projectResultData: [], // 프로젝트 검색 결과 데이터
      selectedProjectData: {}, // 프로젝트 검색 결과에서 선택된 데이터
      key: null, // 과제고유번호
      keyword1: '', // 검색어 SRWR
      keyword2: '', // 검색어 SRWR
      keyword3: '', // 검색어 SRWR
      select1: 'AU01=', // 검색 필드 searchFd 책임연구자
      select2: '%26TI01=', // 검색 필드 searchFd 과제명
      select3: '%26PB01=', // 검색 필드 searchFd 수행기관

      // ********** 논문 데이터 **********
      selectedPaperData: [], // 프로젝트 검색 결과에서 선택된 논문

      // ********** 지재권 데이터 **********
      selectedPatentData: [], // 프로젝트 검색 결과에서 선택된 지재권
      registeredKeys: []
    }
  },
  provide: {
    grid: [Sort, Toolbar, Search, Page]
  },
  setup() {},
  created() {},
  async mounted() {
    const user = this.$store.state.userStore.info
    if (user.groupName === 'lab') {
      this.labNo = user.labNo
      this.$refs.labNoInput.setAttribute('readonly', true)
    }
    this.registeredKeys = (await getLabPjt())?.items.map((item) => item.projectNumber) ?? []
  },
  unmounted() {},
  methods: {
    // ********** 목록 버튼 **********
    goProjectList() {
      this.$router.push({
        path: '/admin/board/project'
      })
    },

    // ********** 프로젝트 검색 버튼 **********
    /**
     * 과제고유번호로 과제 검색
     * @param {} pjt_num 검색어 v-model
     */
    async projectNumberSearch() {
      if (!this.pjt_num) {
        alert('검색어를 입력해주세요')
      } else {
        const result = await searchPjt(this.pjt_num)
        this.projectResultData.push(result)
        this.searchedProjectData = { result: this.projectResultData, count: 1 }
      }
    },

    /**
     * 프로젝트 검색
     * @param {} keyword 검색 키워드
     * @param {*} select 검색 옵션
     */
    async projectSearchResult() {
      // 검색어, 검색 필드에 값 넣어주기
      // 책임 연구자 설정
      if (this.projectManager) {
        this.keyword1 = this.projectManager
      } else {
        this.keyword1 = ''
      }

      // 과제명 설정
      if (this.projectTitleKr) {
        this.keyword2 = this.projectTitleKr
      } else {
        this.keyword2 = ''
      }

      // 수행기관 설정
      if (this.projectLeadAgency) {
        this.keyword3 = this.projectLeadAgency
      } else {
        this.keyword3 = ''
      }

      this.isLoading = true

      if (!this.keyword1 && !this.keyword2 && !this.keyword3) {
        alert('검색어를 입력해주세요')
      } else {
        // 프로젝트 검색 함수 호출 -> 검색 결과 projectResultData에 담기
        await getTotalPjtData(1, this.keyword1, this.keyword2, this.keyword3, this.select1, this.select2, this.select3).then((gridData) => {
          this.projectResultData = gridData
          this.keyword1 = ''
          this.keyword2 = ''
          this.keyword3 = ''
        })
        this.searchedProjectData = this.projectResultData.LIST
      }

      this.isLoading = false
    },

    // ********** 프로젝트 검색 결과 그리드 행 선택 시 이벤트 **********
    async projectSearchReaultSelected(row) {
      if (this.registeredKeys.includes(row.data.projectNumber)) {
        return alert('이미 등록된 프로젝트입니다.')
      }

      this.isLoading = true

      // 선택된 행의 과제고유번호 key 로 등록
      this.key = row.data.projectNumber

      // 선택된 행의 과제고유번호에 해당하는 프로젝트 정보
      this.selectedProjectData = await searchPjt(this.key)

      console.log(this.selectedProjectData)

      // 선택된 행의 과제고유번호에 해당하는 논문, 지재권 정보
      const select = 'TI03'
      const { LIST: LISTPaper } = await searchPaperAll(select === 'TI03' ? this.key : { addQuery: `${select}=${this.key}` })
      const { LIST: LISTPatent } = await searchPatentAll(select === 'TI03' ? this.key : { addQuery: `${select}=${this.key}` })

      // 프로젝트에 할당된 논문이 있을 때 논문 그리드 보여주기
      if (LISTPaper.length > 0) {
        document.querySelector('.paperResultGrid').style.display = 'block'
        this.selectedPaperData = LISTPaper
      } else {
        document.querySelector('.paperResultGrid').style.display = 'none'
      }

      // 프로젝트에 할당된 지재권가 있을 때 지재권 그리드 보여주기
      if (LISTPatent.length > 0) {
        document.querySelector('.patentResultGrid').style.cssText = 'display: block;'
        this.selectedPatentData = LISTPatent
      } else {
        document.querySelector('.patentResultGrid').style.display = 'none'
      }

      // this.selectedPatentData = LISTPatent

      this.isLoading = false
    },

    // ********** 저장 버튼 **********
    saveProject() {
      // 사용자 입력 데이터
      const projectInputData = {
        labNo: this.labNo,
        projectStatus: this.projectStatus,
        projectPeriodStart: this.projectPeriodStart,
        projectPeriodEnd: this.projectPeriodEnd,
        projectAddTextKr: this.projectAddTextKr,
        projectAddTextEn: this.projectAddTextEn
        // "projectNumber": this.selectedProjectData.proje
      }
      if (!this.labNo) {
        alert('연구실 번호를 입력해주세요.')
      } else {
        // 프로젝트 저장 함수 호출
        insertPjtTable(this.selectedProjectData, projectInputData)
        insertPaperTable(this.selectedPaperData, this.labNo)
        insertPatentTable(this.selectedPatentData, this.labNo)

        alert('저장되었습니다.')
        this.goProjectList()
      }
    }
  }
}
</script>

<template>
  <LoadingVue v-if="isLoading" />

  <!-- 검색 구역 시작 -->
  <div id="projectSearchArea">
    <div class="adminTable">
      <table>
        <colgroup>
          <col width="20%" />
          <col width="80%" />
        </colgroup>
        <tbody>
          <tr>
            <th>연구실 번호</th>
            <td>
              <input type="number" v-model="labNo" name="labNo" ref="labNoInput" />
            </td>
          </tr>
          <tr>
            <th>과제고유번호</th>
            <td>
              <label class="input-wrapper input_pjt_num">
                <input
                  type="text"
                  id=""
                  class=""
                  placeholder="과제고유번호 입력"
                  v-model="pjt_num"
                  v-on:keyup.enter="projectNumberSearch"
                />
                <button type="button" id="" @click="projectNumberSearch">
                  <span class="material-icons-outlined"> search </span>
                </button>
              </label>
            </td>
          </tr>
          <tr>
            <th>책임연구자 / 과제명 / 수행기관</th>
            <td>
              <label class="input-wrapper search_input">
                <input
                  type="text"
                  id=""
                  class=""
                  placeholder="책임연구자 입력"
                  v-model="projectManager"
                  v-on:keyup.enter="projectSearchResult"
                />
                <input
                  type="text"
                  id=""
                  class=""
                  placeholder="과제명 입력"
                  v-model="projectTitleKr"
                  v-on:keyup.enter="projectSearchResult"
                />
                <input
                  type="text"
                  id=""
                  class=""
                  placeholder="수행기관 입력"
                  v-model="projectLeadAgency"
                  v-on:keyup.enter="projectSearchResult"
                />
                <button type="button" id="" @click="projectSearchResult">
                  <span class="material-icons-outlined"> search </span>
                </button>
                <span style="margin-left: 15px; width: 230px">1000건 이하만 검색 가능합니다.</span>
              </label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <br />
  <!-- 검색 구역 끝 -->

  <!-- 검색 결과 구역 시작 -->
  <div id="projectResultArea">
    <!-- 검색 결과 그리드 시작 -->
    <div class="pjtResultGrid">
      <ejs-grid
        :dataSource="searchedProjectData"
        :allowSorting="true"
        width="1620"
        :toolbar="toolbarOptions"
        :rowSelected="projectSearchReaultSelected"
        :allowPaging="true"
        :pageSettings="pageSettings"
      >
        <e-columns>
          <e-column field="projectNo" headerText="번호" width="80" textAlign="center"></e-column>
          <e-column field="projectNumber" headerText="과제고유번호" :disableHtmlEncode="false" width="150" textAlign="center"></e-column>
          <e-column field="projectManager" headerText="책임연구자" :disableHtmlEncode="false" width="120" textAlign="center"></e-column>
          <e-column field="projectTitleKr" headerText="과제명" :disableHtmlEncode="false" width="400" textAlign="center"></e-column>
        </e-columns>
      </ejs-grid>
    </div>
    <br />
    <!-- 검색 결과 그리드 끝 -->

    <!-- <div class="e-statustext" v-if="key">
      과제고유번호: <b>{{ key }}</b>
    </div>
    <br /> -->

    <!-- 선택된 논문 검색 결과 그리드 시작 -->
    <div class="paperResultGrid">
      <ejs-grid
        :dataSource="selectedPaperData"
        :allowSorting="true"
        v-model="selectedPaper"
        width="1620"
        :allowPaging="true"
        :pageSettings="pageSettings"
      >
        <e-columns>
          <e-column field="paperNo" headerText="번호" :disableHtmlEncode="false" width="80" textAlign="center"></e-column>
          <e-column field="paperResultTitle" headerText="논문명" :disableHtmlEncode="false" width="300" textAlign="center"></e-column>
          <e-column field="paperJournalName" headerText="학술지명" :disableHtmlEncode="false" width="120" textAlign="center"></e-column>
          <e-column field="paperIssnNumber" headerText="ISSN 번호" :disableHtmlEncode="false" width="150" textAlign="center"></e-column>
          <e-column field="paperAuthor" headerText="저자명" width="180" :disableHtmlEncode="false" textAlign="center"></e-column>
          <e-column field="paperProjectID" headerText="과제고유번호" :disableHtmlEncode="false" width="180" textAlign="center"></e-column>
          <e-column field="paperPubYear" headerText="성과연도" :disableHtmlEncode="false" width="180" textAlign="center"></e-column>
        </e-columns>
      </ejs-grid>
    </div>
    <br />
    <!-- 논문 검색 결과 그리드 끝 -->

    <!-- 선택된 지재권 검색 결과 그리드 시작 -->
    <div class="patentResultGrid">
      <ejs-grid
        :dataSource="selectedPatentData"
        :allowSorting="true"
        v-model="selectedPatent"
        width="1620"
        :allowPaging="true"
        :pageSettings="pageSettings"
      >
        <e-columns>
          <e-column field="patentNo" headerText="번호" :disableHtmlEncode="false" width="80" textAlign="center"></e-column>
          <e-column field="patentResultTitle" headerText="출원등록명" :disableHtmlEncode="false" width="500" textAlign="center"></e-column>
          <e-column field="patentRegistrant" headerText="출원등록인" :disableHtmlEncode="false" width="180" textAlign="center"></e-column>
          <e-column
            field="patentRegistCountry"
            headerText="출원등록국"
            :disableHtmlEncode="false"
            width="120"
            textAlign="center"
          ></e-column>
          <e-column
            field="patentRegistNumber"
            headerText="출원등록번호"
            :disableHtmlEncode="false"
            width="150"
            textAlign="center"
          ></e-column>
          <e-column field="patentYear" headerText="성과연도" width="150" :disableHtmlEncode="false" textAlign="center"></e-column>
        </e-columns>
      </ejs-grid>
    </div>
    <br />
    <!-- 지재권 검색 결과 그리드 끝 -->
  </div>
  <!-- 검색 결과 구역 끝 -->

  <!-- 프로젝트 사용자 입력 구역 시작 -->
  <div class="projectInputArea">
    <span class="info_text">NTIS 정보</span>
    <div class="adminTable">
      <!-- 선택된 프로젝트 검색 결과 시작 -->
      <table>
        <colgroup>
          <col width="10%" />
          <col width="40%" />
          <col width="10%" />
          <col width="40%" />
        </colgroup>
        <tbody>
          <!-- 수정 불가 정보 시작 -->
          <tr>
            <th>과제명(한글)</th>
            <td><input type="text" name="projectTitleKr" v-model="selectedProjectData.projectTitleKr" disabled size="70" /></td>
            <th>예산사업명</th>
            <td><input type="text" name="projectBudgetName" v-model="selectedProjectData.projectBudgetProject" disabled size="70" /></td>
          </tr>
          <tr>
            <th>과제명(영어)</th>
            <td><input type="text" name="projectTitleEn" v-model="selectedProjectData.projectTitleEn" disabled size="70" /></td>
            <th>연구책임자명</th>
            <td><input type="text" name="projectManagerName" v-model="selectedProjectData.projectManager" disabled size="70" /></td>
          </tr>
          <tr>
            <th>과제고유번호</th>
            <td><input type="text" name="projectNumber" v-model="selectedProjectData.projectNumber" disabled size="70" /></td>
            <th>과제수행기관</th>
            <td><input type="text" name="projectLeadAgency" v-model="selectedProjectData.projectLeadAgency" disabled size="70" /></td>
          </tr>
          <tr>
            <th>기준연도</th>
            <td><input type="text" name="projectYear" v-model="selectedProjectData.projectYear" disabled /></td>
            <th>부처명</th>
            <td><input type="text" name="projectMinistryName" v-model="selectedProjectData.projectMinistry" disabled size="70" /></td>
          </tr>
          <tr>
            <th>정부투자연구비</th>
            <td>
              <input type="text" name="projectGovernmentFunds" v-model="selectedProjectData.projectGovernmentFunds" disabled size="70" />
            </td>
            <th>총 연구비</th>
            <td><input type="text" name="projectTotalFunds" v-model="selectedProjectData.projectTotalFunds" disabled size="70" /></td>
          </tr>
          <tr>
            <th>연구내용</th>
            <td><textarea type="text" name="projectAbstract" v-model="selectedProjectData.projectAbstract" disabled></textarea></td>
            <td></td>
            <td></td>
          </tr>
          <br />
          <!-- 수정 불가 정보 끝 -->

          <!-- 수정 가능 정보 시작 -->
          <span class="info_text">추가입력정보</span>
          <tr>
            <th>프로젝트 상태</th>
            <td>
              <select name="projectStatus" id="projectStatus" v-model="projectStatus">
                <option value="선택" selected>선택</option>
                <option value="진행중">진행중</option>
                <option value="완료">완료</option>
              </select>
            </td>
            <td></td>
            <td></td>
          </tr>
          <br />
          <tr>
            <th>과제기간</th>
            <td>
              <input type="date" name="projectPeriodStart" v-model="projectPeriodStart" placeholder="시작일" />
              <span> ~ </span>
              <input type="date" name="projectPeriodEnd" v-model="projectPeriodEnd" placeholder="종료일" />
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <th>추가내용</th>
            <td><textarea type="text" name="projectAddTextKr" v-model="projectAddTextKr" placeholder="요약정보(한글)"></textarea></td>
            <td></td>
            <td><textarea type="text" name="projectAddTextEn" v-model="projectAddTextEn" placeholder="요약정보(영어)"></textarea></td>
          </tr>
          <!-- 수정 가능 정보 끝 -->
        </tbody>
      </table>
      <!-- 선택된 프로젝트 검색 결과 끝 -->
    </div>
  </div>
  <!-- 프로젝트 사용자 입력 구역 끝 -->

  <!-- 버튼 구역 -->
  <div class="btnArea">
    <button class="e-btn wf__btn btn-primary" @click="goProjectList()">목록</button>
    <button id="saveProjectBtn" class="e-btn wf__btn btn-success ml-2" @click="saveProject">저장</button>
  </div>
</template>

<style scoped>
.info_text {
  color: #2a7097;
  margin-left: 10px;
  font-size: x-large;
}
#projectSearchArea {
  display: flex; /* 하위 요소 가로 배치 */
  flex-direction: column; /* 배치 방향 설정 */
  flex-wrap: wrap; /* 줄넘김 처리. nowrap: 넘치면 삐져나감. wrap: 줄넘김 */
  align-content: stretch; /* 아이템들의 행이 2줄 이상 되었을 때 수직축 방향 결정*/
  justify-content: flex-start; /* 메인축 방향 정렬 */
  align-items: flex-start; /* 수직축 방향 정렬 */
}
.projectInputArea textarea {
  width: 540px;
  height: 100px;
}

/* 검색 */
.input-wrapper {
  display: flex;
}
.input-wrapper input {
  width: 25em;
  border: 2px solid var(--wf-primary-color);
  padding: 0.5em 1em;
}
.input-wrapper input::placeholder {
  color: var(--wf-gray-color);
}
.input-wrapper button {
  background-color: var(--wf-primary-color);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  padding: 0.3em;
}
.input-wrapper button span {
  color: #fff;
  font-size: 28px;
}
.search_input > input {
  margin-right: 10px;
}

/* 프로젝트 상태 select */
#projectStatus {
  width: 150px;
  height: 30px;
  border: 1px solid #ededed;
  -webkit-appearance: none; /* 화살표 제거 for chrome */
  -moz-appearance: none; /* 화살'표 제거 for firefox */
  appearance: none;
  background: url('../../../../assets/images/icons/downArrowIcon.png') calc(100% - 5px) center no-repeat;
  background-size: 20px;
  padding: 5px 30px 5px 10px;
  color: #acacac;
  font-weight: 500;
}
#projectStatus::-ms-expand {
  display: none; /* html selectbox 기본 스타일 제거 */
}
#projectStatus option {
  background: #fff;
  color: #18a1e9;
  padding: 3px 0;
}

.btnArea {
  margin-top: 30px;
}

/* 논문, 지재권 그리드 숨기기 */
.paperResultGrid {
  display: none;
}

.patentResultGrid {
  display: none;
}
</style>
